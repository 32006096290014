import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import { Layout, Wrapper, Title, SEO, Header } from "../components";
import website from "../../config/website";

const Hero = styled.header`
  background-color: ${props => props.theme.colors.primary};
  padding-top: 1rem;
  padding-bottom: 2rem;
  color: ${props => props.theme.colors.white};
  div {
    padding-top: 3em;
  }
  h1 {
    color: ${props => props.theme.colors.white};
  }
`;

const Headline = styled.p`
  font-family: "Source Sans Pro", -apple-system, "BlinkMacSystemFont",
    "Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  color: ${props => props.theme.colors.greyBlue};
  font-size: 1.25rem;
  a {
    font-style: normal;
    font-weight: normal;
  }
`;

const Category = ({}) => (
  <Layout>
    <SEO title={`Contact`} pathname="contact" />
    <Header />
    <Hero>
      <Wrapper>
        <Headline>Contact Me</Headline>
      </Wrapper>
    </Hero>
    <Title style={{ marginTop: "4rem" }}>Contact me now</Title>
  </Layout>
);

export default Category;
